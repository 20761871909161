#div-togglebtn label {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    top: 0px
}
#div-togglebtn input {
    opacity: 0;
    width: 0;
    height: 0;
}
/* Span = before clicked */
/* For the background before "clicked" - orig:#2c3e50 */
/* Same as sun icon #d98b04 */
/* Current page bg #edf3f5 + blue-er d4f1fa*/
#div-togglebtn span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.5s;
    cursor: pointer;
    background: #d4f1fa;
    border-radius: 30px;
}
#div-togglebtn strong {
    position: absolute;
    left: 100%;
    width: max-content;
    height: 100%;
    line-height: 20px;
    margin-left: 5px;
    cursor: pointer;
}
/* Color of button = #fff, grey = #a6a495 */
/* Same as sun icon #d98b04 */
#div-togglebtn span:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #edba1f;
  border-radius: 50%;
  transition: 0.5s;
}
/* checked + span = After clicked */
/* Changes background color when/after clicked - #00c853 */
/* transition dark color and bgcolor: #282c34 */
#div-togglebtn input:checked + span {
  background-color: #333333;
}
#div-togglebtn input:checked + span:before {
  transform: translateX(20px);
}
/*
.dark-mode {
filter: invert(100%) grayscale(50%) brightness(80%) ;
background-color: #111111; 
}
/* working mode */
.dark-mode {
filter: invert(100%) grayscale(20%) !important;
background-color: #000000 !important; 
}
